@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700;900&display=swap");

@tailwind base;
@tailwind utilities;
@tailwind components;

html {
  @apply font-roboto;
}

@layer base {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
  }
}

@layer utilities {
  .clip-path-tooltip {
    clip-path: polygon(50% 50%, 0 0, 100% 0);
  }
}

@layer components {
  .text-input-animation > input:focus {
    @apply text-black;
  }

  .text-input-animation > div.not-focused {
    @apply text-base text-gray-500;
    transition: all 0.1s ease-in-out;
    top: 13px;
  }

  .text-input-animation > div.is-focused {
    @apply text-xs text-gray-500;
    transition: all 0.1s ease-in-out;
    top: 3px;
  }
}
